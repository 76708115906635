@import '../../../styles/main.scss';

.ClientInformation {
  .photo-id-container {
    margin-top: 1rem;
    .embedded-photo-id {
      background-color: $off-white;
      max-width: 100%;
      min-height: 300px;
      max-height: 500px;
      &.isPDF {
        width: 100%;
        height: 400px;
      }
    }
    &.fullscreen {
      .embedded-photo-id {
        &.isPDF {
          height: 100vh;
          max-height: 100vh;
        }
      }
      .EquiButton {
        position: absolute;
        top: 10px;
        right: 140px;
        text-align: center;
      }
    }
  }
}
