.KYCTimeline {
  &.p-timeline.p-timeline-vertical {
    .p-timeline-event {
      min-height: 4rem;
      .p-timeline-event-opposite {
        flex: 0 1;
        padding: 0;
      }
    }
  }
}
