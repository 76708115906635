@import '../../../styles/main.scss';

.EquiInput {
  font-size: 12px;
  @include desktop {
    font-size: 16px;
  }
  @include large-desktop {
    font-size: 20px;
  }
  .label {
    white-space: nowrap;
    &.wrap {
      white-space: pre-wrap;
    }
    font-size: 12px;
    @include desktop {
      font-size: 16px;
    }
    @include large-desktop {
      font-size: 20px;
    }
  }
  .input-note {
    margin: 0;
    padding-left: 5px;
    font-size: large;
    font-style: italic;
  }
  .equi-input {
    &:focus {
      border-width: 4px;
    }
  }

  &.equi-input-error {
    font-size: 10px;
    @include desktop {
      font-size: 12px;
    }
    @include large-desktop {
      font-size: 16px;
    }
    .equi-input {
      border-color: $danger;
    }
    div.error {
      margin: 0;
      text-align: right;
    }
    .SpecialInput {
      label {
        color: $danger;
      }
    }
    .input-container {
      .field-prefix {
        border-right-color: $danger;
      }
    }
  }
  .input-container {
    display: flex;
    position: relative;
    .CountryCodesSelect {
      &:disabled {
        opacity: 1;
        color: rgba(black, 0.7);
      }
      border-right: none;
      &:focus {
        border-right: 4px solid $blue-gray;
      }
      padding: 1%;
      max-width: 33%;
      font-size: 12px;
      @include desktop {
        font-size: 12px;
      }
      @include large-desktop {
        font-size: 16px;
      }
    }
    .CountryCodesSelect:focus + input {
      border-left: none;
    }
    $left: 12px;
    $right: 8px;
    .field-prefix {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $light-black;
      position: absolute;
      z-index: 1;
      left: $left;
      border-right: 2px solid $blue-gray;
      padding-right: $right;
      font-size: 12px;
      font-weight: 600;
      @include desktop {
        font-size: 16px;
      }
      @include large-desktop {
        font-size: 20px;
      }
    }
    .withFieldPrefix {
      padding-left: calc(#{$right} + #{$left} + 14px);
    }
  }
  .EquiRadioCheck {
    margin-right: 15px;
    input {
      margin: 0;
      padding: 0;
    }

    .label {
      margin-left: 0;
      padding-left: 5px;
    }

    input,
    label {
      cursor: pointer;
    }
  }
  .equi-input {
    width: 100%;
    height: 48px;
    border: 2px solid $blue-gray;
    background-color: $white;
    border-radius: 0px;
    box-shadow: $gray 2px 2px 4px 0px;
    font-family: Raleway;
    font-size: 12px;
    @include desktop {
      font-size: 16px;
    }
    @include large-desktop {
      font-size: 20px;
    }
    letter-spacing: 1px;
    word-spacing: 1px;
    padding: 0px 10px;
    &:focus {
      border-radius: 0px;
      outline: none;
    }
    option:disabled {
      color: $light-gray;
    }
  }
  .title-label {
    margin: 0;
    margin-right: 20px;
  }
  :disabled {
    background-color: lightgray;
  }
  .read-only:disabled {
    background-color: white;
    color: black;
    opacity: 1;
  }
  .equi-file:disabled {
    background-color: inherit;
  }
}
