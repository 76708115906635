@import '../../../styles/main.scss';

.GeneralInput {
  font-size: 16px;
  @include large-desktop {
    font-size: 20px;
  }
  select {
    cursor: pointer;
  }
  &.text-sm {
    .label {
      font-size: 16px;

      @include large-desktop {
        font-size: 20px;
      }
    }
  }
  .label {
    white-space: nowrap;
    font-size: 16px;
    &.wrap {
      white-space: normal;
    }
    @include large-desktop {
      font-size: 20px;
    }
  }
  &.text-sm {
    .label {
      font-size: 12px;

      @include large-desktop {
        font-size: 16px;
      }
    }
  }
  .equi-input {
    &:focus {
      border-width: 4px;
    }
  }
  &.equi-input-error {
    .equi-input {
      border-color: $danger;
    }
    p.error {
      margin: 0;
      text-align: right;
    }
    .SpecialInput {
      label {
        color: $danger;
      }
    }
  }
  .EquiRadioCheck {
    margin-right: 15px;
    input {
      margin: 0;
      padding: 0;
    }

    .label {
      margin-left: 0;
      padding-left: 5px;
    }

    input,
    label {
      cursor: pointer;
    }
    input:disabled,
    input:read-only {
      cursor: default;
      + label {
        cursor: default;
      }
    }
  }
  .SpecialInput {
    input {
      margin: 0;
    }
    .label {
      margin-left: 0;
      padding-left: 5px;
    }
    label,
    input {
      cursor: pointer;
    }
    .equi-range {
      -webkit-appearance: none;
      width: 100%;
      height: 40px;
      background: transparent;
      @mixin slider-thumb-style {
        cursor: pointer;
        outline: none;
        border: 1px solid $gray;
        border-radius: 4px;
        height: 24px;
        width: 19px;
        background: $blue;
        margin-top: -8px;
      }
      @mixin slider-track-style {
        margin-left: 3px;
        margin-right: 3px;
        cursor: pointer;
        background: $equigenesis-gold;
        border-color: $gray;
        border-radius: 4px;
        height: 9px;
      }
      @mixin slider-ms-fill {
        background: $equigenesis-gold;
        border-radius: 4px;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        @include slider-thumb-style();
      }
      &::-moz-range-thumb {
        @include slider-thumb-style();
      }
      &::-ms-thumb {
        @include slider-thumb-style();
      }
      &::-webkit-slider-runnable-track {
        @include slider-track-style();
      }
      &::-moz-range-track {
        @include slider-track-style();
      }
      &::-ms-track {
        @include slider-track-style();
      }

      &::-ms-fill-lower {
        @include slider-ms-fill();
      }
      &::-ms-fill-upper {
        @include slider-ms-fill();
        border: 1px solid $gray;
      }
      &:focus {
        outline: none;
        &::-ms-fill-lower {
          @include slider-ms-fill();
        }
      }
    }
    .equi-file {
      font-family: Raleway;
      font-size: 12px;
      @include desktop {
        font-size: 16px;
      }
      @include large-desktop {
        font-size: 20px;
      }
      &:focus {
        outline: none;
      }
    }
    .equi-checkbox,
    .equi-radio {
      min-width: 20px;
      width: 20px;
      height: 20px;
      margin: 0px;
    }
    input:disabled,
    input:read-only {
      cursor: default;
      & + label {
        cursor: default;
      }
    }
  }
  .equi-input:not(.equi-file):not(.equi-checkbox) {
    width: 100%;
    border: 2px solid $blue-gray;
    background-color: $white;
    border-radius: 0px;
    box-shadow: $gray 2px 2px 4px 0px;
    font-family: Raleway;
    letter-spacing: 1px;
    word-spacing: 1px;
    padding: 0px 10px;
    height: 34px;
    font-size: 12px;
    @include desktop {
      height: 38px;
      font-size: 16px;
    }
    @include large-desktop {
      height: 40px;
      font-size: 18px;
    }
    &:focus {
      border-radius: 0px;
      outline: none;
    }
    option:disabled {
      color: $light-gray;
    }
  }
  .title-label {
    margin: 0;
    margin-right: 20px;
  }
}
