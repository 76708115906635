@import '../../../styles/main.scss';

.LinkCard {
  background-color: $white;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  padding: 1.4rem;
  border: 3px solid transparent;
  box-shadow: 1px 2px 14px 2px rgba(0, 0, 0, 0.25);
  min-width: 230px;
  min-height: 200px;
  @include large-desktop {
    width: 280px;
    min-height: 240px;
  }
  .children-container {
    margin-bottom: 1.2rem;
    @include large-desktop {
      margin-bottom: 1.3rem;
    }
  }
  &:hover {
    text-decoration: none;
    color: darken($equigenesis-gold, 5%);
    border-color: $equigenesis-dark-gold;
  }
  &.light {
    .Icon {
      color: $equigenesis-gold;
    }
  }
  h2 {
    margin: 0;
    font-size: 1.3rem;
    max-width: 20rem;
    @include large-desktop {
      font-size: 1.4rem;
    }
    &.small-h2 {
      font-size: 1.2rem;
      @include large-desktop {
        font-size: 1.3rem;
      }
    }
  }
  .icon-container {
    flex: 1;
    display: flex;
    align-items: flex-end;
  }
  .Icon {
    @include box(67px);
    &.icon-agent {
      margin-left: 27px;
    }
    @include large-desktop {
      @include box(90px);
      &.icon-agent {
        margin-left: 33px;
      }
    }
  }
}

.LinkCard-container.grid {
  max-width: 1000px;
  margin-top: 3rem;
  gap: 1rem;
  &.grid-1 {
    grid-template-columns: repeat(1, 1fr);
    @include large-desktop {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &.grid-2 {
    grid-template-columns: repeat(2, 1fr);
    @include large-desktop {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &.grid-3 {
    grid-template-columns: repeat(3, 1fr);
    @include large-desktop {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @include large-desktop {
    margin-top: 4rem;
    margin-bottom: 1rem;
    gap: 3rem;
  }
  @include large-height-desktop {
    margin-top: 4rem;
  }
  .LinkCard {
    min-width: 220px;
    width: 100%;
    max-width: 230px;
    @include large-desktop {
      max-width: 280px;
    }
  }
}
