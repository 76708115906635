@import '../../../styles/main.scss';
.OfficeUseButton {
  width: 100%;
  cursor: pointer;
  font-size: 1.2rem;
  color: $equigenesis-dark-gold;
  &:hover {
    color: $equigenesis-dark-gold;
    font-weight: bold;
  }
  .Icon {
    width: 20px;
    height: 20px;
    height: unset;
    display: inline;
    margin-right: 7px;
  }
}
