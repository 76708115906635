.AccreditedInvestorStatus {
  .ai-description {
    max-width: 670px;
    margin-bottom: 1rem;
  }
  .EquiInput {
    .SpecialInput > div {
      align-items: flex-start;
      input {
        margin-top: 4px;
      }
      .label.wrap {
        margin-left: 10px;
        max-width: 95%;
      }
    }
  }
}
