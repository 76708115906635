@import '../../../styles/main.scss';

.AdminAddUserType {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  form.card {
    width: 100%;
    background: radial-gradient(at 80% 56%, $equigenesis-medium-light-gold 0%, $equigenesis-gold 100%);
    $padding: 40px;
    margin-top: 50px;
    width: 600px;
    padding: $padding;
    margin-top: 40px;
    color: white;
    max-width: 70rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
  }
  .GeneralInput {
    margin-bottom: 30px;
  }
  .EquiButton {
    margin-top: 30px;
    width: fit-content;
  }
}
