@import '../../styles/main.scss';

.NotFound {
  color: darken($equigenesis-dark-gold, 5%);
  margin-bottom: 30%;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 0px;
  }
  .not-found-container {
    p {
      margin-bottom: 50px;
      font-size: 1.5rem;
      font-weight: 500;
    }
    .Icon {
      @include box(200px);
    }
  }
}
