@import '../../styles/main.scss';

.SubscriberSubscriptionPackagesPage {
  .no-subscription-packages {
    h1 {
      margin-top: 10%;
      margin-bottom: 1%;
    }
    a {
      margin-top: 10%;
    }
  }
  .table-grid-template-columns {
    grid-template-columns: 1.3fr 0.4fr 1.3fr 0.8fr;
  }
  .filter-label {
    white-space: nowrap;
  }
  .GeneralInput.filter-by-product-name {
    max-width: 33%;
  }
}
