@import '../../styles/main.scss';

.PageLayout.ViewOrganizationsPage {
  .page-title {
    color: $equigenesis-gold;
    h1 {
      margin-top: -10px;
      margin-bottom: 10px;
    }
    .Icon {
      @include box(100px);
      margin-left: 33px;
    }
  }
  .table-grid-template-columns {
    grid-template-columns: 2fr 1fr;
  }
  .table-cell:first-child {
    justify-content: flex-start;
    padding-left: 1rem;
    text-align: left;
  }
}
