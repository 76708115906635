@import '../../styles/main';

.nav {
  $desktop-margin: calc(10px + 0.8rem);
  $margin: calc(10px + 0.4rem);
  background-color: $white;
  box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.25);
  padding: 0 10px;
  height: $desktop-nav-bar-height;
  @include large-desktop {
    padding: 0 40px;
  }
  .nav-container {
    align-items: flex-end;
    .nav-left {
      display: none;
      @include desktop {
        display: flex;
      }
      align-items: flex-end;
      height: 100%;
      margin-left: $margin;
      @include large-desktop {
        margin-left: $desktop-margin;
      }
      &:hover {
        .NavLink {
          opacity: 0.4;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .nav-links {
      align-items: flex-end;
      height: 100%;
      .NavLink {
        height: 100%;
        &.active {
          font-weight: bold;
          color: $equigenesis-dark-gold;
          text-decoration: underline;
        }
      }
    }
    .nav-right {
      align-items: center;
      height: 100%;
    }
  }
}
