@import '../../../styles/main.scss';

.UserAvatar {
  @include box(40px);
  min-width: 40px;
  min-height: 40px;
  border-radius: 100%;
  border: 3px solid $equigenesis-gold;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
