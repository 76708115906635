.NewSubscriptionPackageFormModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  .loading-container {
    padding: 30px 40px;
  }
  form {
    padding: 0 50px;
    margin-bottom: 10px;
    .GeneralInput {
      margin: 10px 0px 10px 30px;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
