@import '../../../styles/main.scss';

.Table {
  @include large-desktop {
    &.max-width {
      width: 80%;
    }
  }
  .filter-container {
    margin-bottom: 20px;
    @include large-desktop {
      margin-bottom: 30px;
    }
    .GeneralInput {
      max-width: 250px;
      min-width: 200px;
      @include large-desktop {
        max-width: 300px;
        min-width: 250px;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .table-header-row {
    overflow-y: scroll;
  }
  .table-row {
    width: 100%;
    gap: 10px;
    border-bottom: thin solid $separator-line;
    &:last-child:not(:first-child) {
      border-bottom: none;
    }
    .table-cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      padding: 5px;
      width: 100%;
      &.table-header {
        overflow: visible;
        white-space: normal;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .chevron {
          color: lightgrey;
          margin-left: 5px;
          margin-bottom: 1px;
          width: 10px;
        }
        &.sortable {
          user-select: none;
          -moz-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          &:hover {
            cursor: pointer;
            color: $equigenesis-gold;
            .chevron {
              color: $equigenesis-gold;
            }
          }
          &.selected {
            color: $blue;
            .chevron {
              color: $blue;
            }
          }
        }
      }
    }
  }
  .table-data {
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: 100%;
    max-height: 255px;
    @include large-height-desktop {
      max-height: 400px;
    }
  }
  .NoSearchResults {
    margin-top: 26px;
  }
}
