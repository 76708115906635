@import '../../../styles/main.scss';

.SectionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 16px -7px $equigenesis-dark-gold;
  border-radius: 18px;
  padding: 2rem;
  background-color: $white;
}
