@import '../../styles/main.scss';

.PageLayout.SubscriptionPackagesPage {
  .page-title {
    h1 {
      margin-bottom: 2rem;
    }
  }
  .no-subscription-packages {
    h1 {
      margin-top: 10%;
      margin-bottom: 1%;
    }
    a {
      margin-top: 10%;
    }
  }
  .table-grid-template-columns {
    grid-template-columns: 1.2fr 1fr 0.2fr 1fr 0.8fr;
  }
  .subscriber-name {
    grid-template-columns: 0.5fr 1fr;
    gap: 0.7rem;
    justify-items: left;
    span {
      justify-self: center;
    }
  }
  .filter-label {
    white-space: nowrap;
  }
}
