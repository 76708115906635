.LeveragedTaxShelterInformation {
  h2.title {
    margin-bottom: 30px;
  }
  .form-row {
    max-width: 80%;
    &.max-width {
      max-width: 100%;
    }
  }
}
