@import '../../styles/main.scss';

.NoSearchResults {
  color: darken($light-gray, 10%);
  font-weight: bold;
  .Icon {
    margin-top: 20px;
    @include box(50px);
  }
}
