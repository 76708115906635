@import '../../styles/main.scss';

.SubscriptionPackage {
  .form-state {
    margin-bottom: 0;
  }
  .EquiButton {
    margin: 0px;
    font-size: 15px;
    @include large-desktop {
      font-size: 19px;
    }
  }
  .form-row {
    margin-bottom: 20px;
  }
  .big-font {
    font-size: xx-large;
  }
}
