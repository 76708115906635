@import '../../../styles/main.scss';

$flash-type: (
  success: $success,
  warning: $warning,
  danger: $danger,
);
$flash: 'flash-message';
.FlashMessage.#{$flash} {
  width: 100%;
  border-radius: 5px;
  color: white;
  display: flex;
  padding: 15px;
  font-size: 1.2rem;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  @each $type, $color in $flash-type {
    &.#{$flash}-#{$type} {
      background-color: $color;
    }
  }
  .close {
    cursor: pointer;
  }
}
