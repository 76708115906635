@import '../../../styles/main';

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $loading-overlay-background;
  z-index: $overlay-z-index;
  display: flex;
  justify-content: center;
  align-items: center;
  &.withoutBackground {
    background: transparent;
    bottom: unset;
  }
}
#loading-overlay-root {
  .Overlay {
    z-index: $loading-overlay-z-index;
  }
}
