.InvestmentInformation {
  .separate-children {
    input[type='radio'],
    input[type='radio'] + label,
    input[type='checkbox'],
    input[type='checkbox'] + label {
      margin-top: 0.35rem;
    }
  }
}
