@import '../../../styles/main.scss';

.nav-link {
  height: 100%;
  text-anchor: end;
  align-items: flex-end;
  color: $nav-text-color;
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  text-align: left;
  font-family: Raleway;
  padding: 0 10px;
  padding-bottom: 18px;
  font-size: 16px;
  line-height: 1.5;
  &.logo {
    padding: 0;
    align-items: center;
    .Logo {
      width: 36px;
      @include large-desktop {
        width: 47px;
      }
    }
  }
  &:hover {
    color: $nav-text-hover-color;
  }
  @include large-desktop {
    padding: 0 calc(10px + 0.8rem);
    padding-bottom: 18px;
  }
}
