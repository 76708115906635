@import '../../styles/main.scss';

.App {
  // width: 100vw;
  &.DocuSignCallback {
    .main-container {
      > *,
      main {
        display: flex;
      }
    }
  }
  header {
    position: sticky;
    top: 0;
    z-index: $nav-bar-z-index;
    // width: 100vw;
    .nav-container.container {
      max-width: $nav-bar-max-width;
    }
  }
  .mobile-warning {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $off-white;
    position: fixed;
    top: $desktop-nav-bar-height;
    right: 0;
    left: 0;
    bottom: 0;
    min-height: calc(100vh - #{$desktop-nav-bar-height});
    padding: 0 1rem;
    @include desktop {
      display: none;
    }
    .PageLayout {
      height: 100%;
    }
    h1 {
      text-align: center;
      margin-top: calc(#{$desktop-nav-bar-height});
    }
    h3 {
      text-align: center;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &.branded {
    .main-container {
      min-height: calc(100vh - #{$desktop-nav-bar-height} - 46px);
    }
  }
  .main-container {
    display: grid;
    background-color: $off-white;
    min-height: calc(100vh - #{$desktop-nav-bar-height});
    grid-template-rows: 1fr auto;
    justify-items: center;
    > * {
      display: none;
      @include desktop {
        display: flex;
      }
    }
    main {
      display: none;
      @include desktop {
        display: flex;
      }
      padding: $main-padding;
      max-width: $container-max-width;
      width: 100%;
      flex-direction: column;
      align-items: center;
      min-height: 100%;
      height: fit-content;
    }
    &.app-loading {
      .Loading {
        margin-bottom: $footer-height;
      }
    }
  }
}
