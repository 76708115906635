@import '../../styles/main.scss';

.FlashMessageOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: $flash-z-index;
  .FlashMessage {
    position: absolute;
    bottom: 0;
    transition: bottom 0.3s ease-in;
    width: 100%;
    @include desktop {
      width: 92%;
      max-width: $nav-bar-max-width;
    }
  }
  &.isOpen {
    .FlashMessage {
      bottom: -140px;
    }
  }
}
