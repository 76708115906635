@import '../../styles/main.scss';

.PageLayout.KYCsPage {
  .page-title {
    h1 {
      margin-bottom: 2rem;
    }
  }
  .no-kycs {
    h1 {
      margin-top: 10%;
      margin-bottom: 1%;
    }
    a {
      margin-top: 10%;
    }
  }
  .table-grid-template-columns {
    grid-template-columns: 1.1fr 1.3fr 0.6fr 0.68fr;
  }
  .subscriber-name {
    grid-template-columns: 0.6fr 1fr;
    gap: 0.3rem;
    justify-items: left;
    span {
      justify-self: center;
    }
  }
  .filter-label {
    white-space: nowrap;
  }
}
