@import '../../styles/main.scss';

.UserDropdown {
  a.nav-link {
    text-decoration: none;
  }
  .nav-link {
    height: unset;
    padding: 0;
    display: flex;
    align-items: center;
    user-select: none;

    .UserAvatar {
      margin-left: 10px;
    }
    .name {
      font-weight: 500;
    }
    .roles {
      text-align: center;
      height: 0;
      line-height: 12px;
      font-size: 13px;
    }
  }
}
