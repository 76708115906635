@import '../../styles/main.scss';

.PageLayout.ViewSubscribersPage {
  .page-title {
    color: $equigenesis-gold;
    h1 {
      margin-top: 0px;
    }
    .Icon {
      @include box(100px);
    }
  }
  .table-grid-template-columns {
    grid-template-columns: 1fr 1fr 0.8fr;
    &.four-cols {
      grid-template-columns: 1fr 1fr 0.7fr 1fr;
    }
  }
}
