@import '../../styles/main.scss';

.PageLayout {
  $top-desktop: calc(#{$main-padding} + #{$desktop-nav-bar-height} + 15px);
  .OfficeUseButton,
  .BackButton {
    position: sticky;
    z-index: $nav-bar-z-index - 1;
    top: $top-desktop;
    &.with-branding {
      top: calc(#{$top-desktop} + 30px);
    }
    .actual-button {
      position: absolute;
    }
  }
  .OfficeUseButton {
    .actual-button {
      right: 0;
    }
  }
  .ScrollToTopButton {
    position: sticky;
    z-index: $nav-bar-z-index - 1;
    top: $top-desktop;
    &.with-branding {
      top: calc(#{$top-desktop} + 30px);
    }
    .actual-button {
      max-width: 5rem;
      position: absolute;
      right: -1rem;
      @include large-desktop {
        right: 1.5rem;
      }
    }
  }
  .ScrollToBottomButton {
    position: sticky;
    z-index: $nav-bar-z-index - 1;
    top: calc($top-desktop + 7rem);
    &.with-branding {
      top: calc(#{$top-desktop} + 7rem + 30px);
    }
    .actual-button {
      max-width: 5rem;
      position: absolute;
      right: -1rem;
      @include large-desktop {
        right: 1.5rem;
      }
    }
  }
  .page-title {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include large-height-desktop {
      margin-top: 20px;
      margin-bottom: 30px;
    }
    text-align: center;
    h1 {
      margin-bottom: 0;
    }
  }
}
