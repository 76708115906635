@import '../../styles/main.scss';

.Home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
    .Brand {
      width: 220px;
    }
  }
  .content-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 7rem;
    @include large-desktop {
      gap: 10rem;
    }
  }

  @media screen and (min-height: 850px) {
    .logo-container {
      margin-top: 1.9rem;
      margin-bottom: 4rem;
    }
    .content-container {
      a {
        width: 280px;
        h2 {
          font-size: 24px;
        }
        .Icon {
          @include box(100px);
        }
      }
    }
  }
}
