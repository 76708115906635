@import '../../../styles/main.scss';
.PageLayout.AdminViewSingleUser {
  .page-title {
    margin-bottom: 15px;
    p {
      margin: 0;
      font-size: 1.1rem;
    }
  }
  .EquiButton {
    margin-bottom: -40px;
  }
  .role-icon-container {
    margin-top: 1rem;
    margin-bottom: -1rem;
    color: $blue;
    .role-icon:not(:last-child) {
      margin-right: 10%;
    }
    .role-agent {
      .Icon.icon-agent {
        margin-right: -33px;
        @include box(110px);
      }
      h3 {
        margin-top: calc(1rem - 10px);
      }
    }
  }
  .user-details-container {
    margin-top: 80px;
    max-width: 800px;
    color: $equigenesis-dark-gold;
    font-size: 1.4rem;
    font-weight: 600;
    .user-detail {
      height: 70px;
      padding: 0 1rem;
      &:not(:last-child) {
        border-bottom: thin solid $separator-line;
      }
      .value-item {
        font-weight: normal;
        color: $light-black;
        .Icon {
          @include box(65px);
          color: $equigenesis-dark-gold;
          &.toggle-off {
            color: $light-gray;
          }
        }
      }
    }
  }
}
