@import '../../styles/main.scss';

.SubscriptionPackagePage {
  .status.Icon {
    @include box(40px);
    margin-left: 10px;
    margin-bottom: -10px;
    &.icon-checkmark {
      color: green;
    }
    &.icon-close {
      color: $danger;
    }
  }
  .photo-id-container {
    margin-top: 1rem;
    .embedded-photo-id {
      background-color: $off-white;
      max-width: 100%;
      min-height: 300px;
      max-height: 500px;
      &.isPDF {
        width: 100%;
        height: 400px;
      }
    }
    &.fullscreen {
      .embedded-photo-id {
        &.isPDF {
          height: 100vh;
          max-height: 100vh;
        }
      }
      .EquiButton {
        position: absolute;
        top: 10px;
        right: 140px;
        text-align: center;
      }
    }
  }
}
