.AssignAgentToSubscriberModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  .loading-container {
    padding: 30px 40px;
  }
  form {
    padding: 0 50px;
    margin-bottom: 10px;
    .GeneralInput {
      margin-bottom: 30px;
    }
  }
}
