@import '../../styles/main.scss';

.PageLayout.ViewAgentsPage {
  .page-title {
    color: $equigenesis-gold;
    h1 {
      margin-top: -10px;
      margin-bottom: 10px;
    }
    .Icon {
      @include box(100px);
      margin-left: 33px;
    }
  }
  .table-grid-template-columns {
    grid-template-columns: 1.1fr 1.3fr 1fr 0.8fr 0.5fr;
  }
}
