@import '../../styles/main.scss';

.kyc-state-timeline {
  &.p-timeline.p-timeline-horizontal {
    .p-timeline-event {
      min-height: 0;
    }
    .p-timeline-event-opposite,
    .p-timeline-event-content {
      padding: 0;
    }
  }
}

.KYC {
  .form-state {
    margin-bottom: 0;
  }
  #notes {
    padding: 10px;
  }
}
