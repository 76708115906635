@import '../../../styles/main.scss';
.FinancialInformation {
  .financial-assets {
    .EquiInput {
      margin-top: 15px;
    }
    .form-row {
      margin: 0px;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
        margin-bottom: 0px;
      }
    }
  }
  .eq-grid {
    margin: 20px 0;
    width: 100%;
    .EquiInput {
      margin: 0;
    }
    column-gap: 2%;
    row-gap: 0;
    grid-auto-rows: 30px;
    grid-template-columns: repeat(4, 1fr);
    max-width: 90%;
  }
  .EquiInput {
    textarea {
      height: 200px;
    }
  }
  .debt-button-container {
    display: flex;
    > * {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
}
