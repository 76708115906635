@import '../../../styles/main.scss';
.ScrollToBottomButton {
  cursor: pointer;
  font-size: 1.2rem;
  color: $equigenesis-gold;
  width: 100%;
  &:hover {
    color: $equigenesis-dark-gold;
  }
  .Icon {
    height: 30px;
    display: inline;
    margin-bottom: 5px;
  }
  .actual-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
