.ModifyRolesModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  .grid {
    margin-top: 20px;
    margin-left: 60px;
    align-content: center;
    justify-items: center;
    row-gap: 0rem;
    > * {
      height: 40px;
    }
  }
  .EquiButton {
    margin-top: 40px;
  }
}
