@import '../../styles/main';

.Footer {
  color: darken($light-gray, 3%);
  font-size: 12px;
  height: $footer-height;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 5%;
  flex-shrink: 0;
  .container {
    text-align: center;
    border-top: 1px solid rgba($light-gray, 0.3);
    height: 100%;
    flex: 1 0 0;
    max-width: $nav-bar-max-width;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
