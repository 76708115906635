@import '../../styles/main.scss';

.OfficeUse {
  .form-state {
    margin-bottom: 0;
  }
  #notes {
    padding: 10px;
  }
  .form-row {
    .equi-input {
      height: 200px;
    }
  }
  .center-file-select {
    max-width: 400px;
    strong {
      min-width: 83px;
    }
  }
}
