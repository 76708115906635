.OfficeUseSubmitPDFModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  h1 {
    margin-bottom: 1rem;
  }
  .role-display {
    max-width: 500px;
    width: 100%;
    h3 {
      text-align: center;
      margin: 0;
      margin-bottom: -4px;
    }
    &:last-of-type {
      margin-bottom: 10px;
    }
  }
}
