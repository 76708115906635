@import '../../../styles/main';

.CloseIcon {
  cursor: pointer;
  .hide-hover {
    display: block;
  }
  .show-hover {
    display: none;
    color: $equigenesis-dark-gold;
  }
  &:hover {
    .hide-hover {
      display: none;
    }
    .show-hover {
      display: block;
    }
  }
  .Icon {
    @include box(25px);
  }
}
