$white: rgb(255, 255, 255);
$off-white: rgb(245, 245, 245);
$light-gray: rgb(117, 117, 117);
$gray: rgb(170, 170, 170);
$blue-gray: rgb(111, 143, 168);
$blue: rgb(24, 32, 99);

$nav-text-color: rgb(97, 96, 76);
$nav-text-hover-color: rgb(168, 132, 65);

$equigenesis-light-gold: rgb(214, 203, 174);
$equigenesis-medium-light-gold: rgb(194, 183, 151);
$equigenesis-gold: rgb(162, 144, 96);
$equigenesis-dark-gold: rgb(120, 98, 39);

$light-black: rgba(0, 0, 0, 0.6);

$success: rgb(77, 162, 60);
$warning: rgb(230, 157, 0);
$danger: rgb(220, 53, 69);

$loading-overlay-background: rgba(0, 0, 0, 0.85);

$separator-line: rgb(225, 225, 225);
