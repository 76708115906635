@import '../../styles/main.scss';

.MyDocumentsPage {
  .no-subscription-packages {
    h1 {
      margin-top: 10%;
      margin-bottom: 1%;
    }
    a {
      margin-top: 10%;
    }
  }
  .table-grid-template-columns {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .filter-label {
    white-space: nowrap;
  }
}
