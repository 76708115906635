@import '../../../styles/main.scss';

.Form {
  $padding: 20px 40px;
  $large-padding: 60px;
  position: relative;
  background: $equigenesis-gold;
  border-radius: 15px;
  box-shadow: 0 0px 0px 4px lighten($blue-gray, 10%);
  padding: $padding;
  @include large-desktop {
    padding: $large-padding;
  }
  color: white;
  width: 100%;
  max-width: 600px;
  @include large-desktop {
    max-width: 800px;
  }
  height: fit-content;
  h1 {
    margin-bottom: -40px;
  }
  .submit-buttons-container {
    margin-top: -64px;
    margin-bottom: 13px;
  }
  .title {
    margin: 0;
  }
  h3.header.title:not(.justify-center) {
    margin-right: 20px;
  }
  .form-section {
    margin-bottom: 50px;
  }
  .form-row {
    margin-bottom: 20px;
    > *:not(.no-flex) {
      flex: 1;
    }
  }
  h2 {
    &.title {
      text-align: center;
      margin-bottom: 30px;
      margin-top: 10px;
    }
  }
  .EquiButton {
    margin-bottom: 7px;
    &:disabled.read-only {
      cursor: not-allowed;
    }
  }
  .EquiInput {
    &:not(:last-child) {
      margin-right: 20px;
    }
    .EquiRadioCheck input,
    input,
    select,
    textarea {
      &:disabled {
        cursor: default;
        + label {
          cursor: default;
        }
      }
    }
  }
  select {
    cursor: pointer;
  }
  h1.title {
    margin-bottom: 40px;
  }
  .or-text {
    margin: 25px 20px 15px 0px;
    width: 20px;
    align-items: flex-end;
  }
  hr {
    $offset: 10;
    width: calc(100% + #{$offset * 2px});
    margin: 16px 0 20px;
    margin-left: $offset * -1px;
    &.section-separator {
      margin: 50px 0;
      margin-left: $offset * -1px;
      height: 3px;
      border-radius: 10%;
      background-color: $white;
    }
    &.small-separator {
      margin-bottom: 30px;
    }
  }
}
