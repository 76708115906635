@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin desktop {
  @media screen and (min-width: 769px) {
    @content;
  }
}

@mixin large-desktop {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin large-height-desktop {
  @media screen and (min-height: 900px) {
    @content;
  }
}
