@import '../../../styles/main.scss';

.EquiButton {
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border: none;
  text-align: center;
  font-family: Arial;
  font-size: 19px;
  line-height: 1;
  border-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 6px 0px;
  transition: box-shadow 100ms ease 0s;
  &:focus {
    outline: none;
  }
  &.button-primary {
    background-color: $equigenesis-gold;
    color: $white;
    &:hover {
      background-color: $equigenesis-dark-gold;
    }
  }

  &.button-secondary {
    background-color: $white;
    color: $equigenesis-gold;
    font-weight: normal;
    text-decoration: none;
    font-style: normal;
    &:hover {
      background-color: $equigenesis-light-gold;
      color: $white;
    }
  }

  &.button-secondary,
  &.button-primary {
    &:disabled {
      background-color: darken($gray, 30%);
      color: darken($white, 25%);
      cursor: not-allowed;
    }
  }
}
