@import '../../styles/main.scss';

.PageLayout.ViewOrganizationPage {
  .page-title {
    .edit-icon {
      @include box(30px);
      margin-bottom: -4px;
      color: $equigenesis-dark-gold;
      &:hover {
        color: $equigenesis-gold;
      }
    }
  }
  .account-toggle {
    &.SectionCard {
      padding: 0rem 2rem;
      margin: 1.5rem 0rem;
      .Icon {
        @include box(65px);
        color: $equigenesis-dark-gold;
        &.toggle-off.icon-toggleOff {
          color: $gray;
        }
      }
    }
  }
  .SectionCard {
    margin: 20px 0px;
    div.subscriber {
      height: 3rem;
      align-items: center;
    }
  }
}
