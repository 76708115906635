.Modal {
  border-radius: 5px;
  padding: 2rem;
  background-color: white;
  border: 1px solid black;
  position: relative;
  width: 80%;
  max-width: 700px;
  .CloseIcon {
    $position: 1.2rem;
    position: absolute;
    top: $position;
    right: $position;
  }
}
