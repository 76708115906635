.PageLayout.KYCPage {
  .page-title {
    p {
      margin-bottom: 2rem;
      &:not(:first-of-type) {
        margin-top: 0;
      }
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }
}
