@import '../../../styles/main.scss';
.Dropdown {
  position: relative;
  .menu-container {
    position: absolute;
    padding: 0.5rem 0;
    margin-top: 1rem;
    background-color: $white;
    box-shadow: 0 2px 30px 5px rgba(0, 0, 0, 0.2);
    &:after {
      position: absolute;
      right: 0;
      transform: translateX(-50%);
      top: -10px;
      width: 0;
      height: 0;
      content: '';
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $white;
    }
    .nav-link {
      padding: 0.2rem 1rem;
      &:hover {
        background-color: rgba($gray, 0.1);
      }
    }
  }
}
