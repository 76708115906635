.ClientInformation,
.TaxResidence {
  .button-container {
    // margin-top: 35px;
    align-items: baseline;
    .EquiButton {
      margin-bottom: 0px;
      margin-top: 1.85rem;
    }
  }
}
