@import '../../styles/main.scss';
.PageLayout.MyProfile {
  .page-title {
    p {
      margin-top: 0.2rem;
      font-size: 1.2rem;
    }
  }
  .form-section {
    margin-bottom: 1rem;
  }
  .button-container {
    margin-top: 1.3rem;
    align-items: baseline;
    .EquiButton {
      margin-bottom: 0px;
      margin-top: 1.85rem;
    }
  }
}
