@import './colors';
@import './mixins';
@import './variables';

body {
  margin: 0;
  font-family: Raleway;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.no-scroll {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  background-color: $off-white;
  * {
    box-sizing: border-box;
  }
}

.container {
  width: 100%;
  max-width: $container-max-width;
}

.title-label,
.title {
  font-family: Alata;
}

.width-50 {
  width: 50%;
}

.width-50-minus-spacer {
  width: calc(50% - 15px);
}

.header {
  text-align: left;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

@for $i from 1 to 10 {
  .flex-#{$i} {
    flex: $i;
  }
}
.flex-shrink {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.wrap {
  white-space: pre-wrap;
}

.column {
  flex-direction: column;
}

.grid {
  display: grid;
  justify-items: center;
  align-content: center;
  gap: 3rem;
}

@for $i from 1 through 10 {
  .grid-#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

.relative {
  position: relative;
}

.w-fit-content {
  width: fit-content;
}
.h-fit-content {
  height: fit-content;
}

.absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.absolute-behind {
  position: absolute;
  z-index: $behind-z-index;
}

.spacer {
  margin: 15px;
  &.max-width {
    width: calc(100% - 30px);
  }
}

.border-bottom-1 {
  border-bottom: 1px solid;
}

sup {
  font-size: 0.6em;
}

.bottom-space {
  margin-bottom: 35px;
}

.align-self-start {
  align-self: flex-start;
}

.space-right {
  margin-right: 5px;
}
.space-left {
  margin-left: 5px;
}
.width-60 {
  width: 60%;
  margin: 0 auto;
}
.width-30 {
  width: 30%;
  margin: 0 auto;
}

.equi-color {
  color: $equigenesis-gold;
}

.max-1_3 {
  max-width: 33.3333%;
}
.max-1_4 {
  max-width: 25%;
}
.max-3_10 {
  max-width: 30%;
}

.pointer {
  cursor: pointer;
}

.error {
  color: $danger;
  // height: 10px;
  font-weight: 500;
}

.left {
  display: flex;
  justify-content: left;
}

.max-width {
  width: 100%;
}
.max-height {
  height: 100%;
}
.text-underline {
  text-decoration: underline;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.overflow {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}

@for $i from 1 to 100 {
  .width-#{$i} {
    width: $i * 1%;
  }
  .max-width-#{$i} {
    max-width: $i * 1%;
  }
}

.label {
  font-size: 20px;
  font-family: Raleway;
  margin-left: 5px;
  margin-right: 5px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

a {
  text-decoration: none;
  color: $equigenesis-dark-gold;
  font-weight: 400;
  &:hover {
    color: $equigenesis-gold;
    text-decoration: underline;
  }
}

h1 {
  font-size: 26px;
  @include large-desktop {
    font-size: 32px;
  }
}

h2 {
  font-size: 22px;
  @include large-desktop {
    font-size: 26px;
  }
}

h3 {
  font-size: 18px;
  @include large-desktop {
    font-size: 20px;
  }
}

.darken {
  filter: brightness(85%);
}

.bg-red {
  background-color: rgba(255, 0, 0, 0.4);
}
