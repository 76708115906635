$flash-z-index: 7;
$loading-overlay-z-index: 6;
$overlay-z-index: 5;
$nav-bar-z-index: 4;
$behind-z-index: -1;

$desktop-nav-bar-height: 81px;
$footer-height: 60px;

$main-padding: 3rem;

$nav-bar-max-width: 1600px;
$container-max-width: 1400px;
