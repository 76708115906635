@import '../../styles/main.scss';
.PageLayout.NewKYCPage {
  h1 {
    margin-bottom: 20px;
  }
  .info-icon {
    margin-left: 10px;
    @include box(20px);
    &:hover {
      color: $equigenesis-dark-gold;
    }
  }
  .page-title {
    margin-bottom: 30px;
  }
  .Form {
    max-width: 700px;
  }
}

.CreateNewKYCMoreInfoModal {
  width: 80%;
  max-width: 700px;
}
