@import '../../styles/main.scss';

.SubscriptionPackagePageExecutedCopy {
  .status.Icon {
    @include box(40px);
    margin-left: 10px;
    margin-bottom: -10px;
    &.icon-checkmark {
      color: green;
    }
    &.icon-close {
      color: $danger;
    }
  }
  .photo-id-container {
    .embedded-photo-id {
      background-color: $off-white;
      max-width: 100%;
      width: 100%;
      height: calc(70vh - $desktop-nav-bar-height);
    }
    &.fullscreen {
      .embedded-photo-id {
        &.isPDF {
          height: 100vh;
          max-height: 100vh;
        }
      }
      .EquiButton {
        position: absolute;
        top: 10px;
        right: 50%;
        transform: translate(50%, 0);
        text-align: center;
      }
    }
  }
}
