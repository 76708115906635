.NewSubscriberPackagePage {
  .form-row {
    margin: 10px 0px;
  }
  .EquiButton {
    margin-top: 10px;
  }
  p {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}
