.AddAnAgentModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  .loading-container {
    padding: 30px 40px;
  }
  form {
    padding: 0 50px;
    width: 100%;
    .GeneralInput {
      margin-bottom: 10px;
    }
    .EquiButton {
      margin-top: 20px;
    }
  }
}
